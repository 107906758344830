<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'新建':'编辑'}标签模板`" width="78%" placement="right" :closable="true"
                  :visible="visible" @close="onClose">
            <div>

                <a-form :label-col="{ span: 9, offset: 0 }" :wrapper-col="{ span:15}" v-bind="formItemLayout"
                        @submit="handleSubmit">

                    <a-row>
                        <a-col :span="8">
                            <a-form-item label="模板分类">
                                <a-select v-model="info.tag_type">
                                    <a-select-option :value="1">人员标签模板</a-select-option>
                                    <a-select-option :value="2">仪器标签模板</a-select-option>
                                    <a-select-option :value="3">样品标签模板</a-select-option>
                                    <a-select-option :value="4">委托单标签模板</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="模板名称">
                                <a-input v-model="info.name"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="纸张尺寸(mm)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" label="宽" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:18 }">
                                                <a-input v-model="info.paper_width"/>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="10" offset="1">
                                            <a-form-item style="margin-bottom: 0" label="高" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:18 }">
                                                <a-input v-model="info.paper_height"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row style="padding-top: 25px">
                        <a-col :span="8">
                            <a-form-item label="显示条码">
                                <a-switch v-model="info.Barcode_show"></a-switch>
                            </a-form-item>
                            <a-form-item label="条码大小(mm)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" label="宽" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:16 }">
                                                <a-input-number v-model="info.Barcode_width"/>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="10" offset="1">
                                            <a-form-item style="margin-bottom: 0" label="高" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:16 }">
                                                <a-input-number v-model="info.Barcode_height"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                            <a-form-item label="设置边距(mm)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" label="上边距" :label-col="{ span: 13}"
                                                         :wrapper-col="{ span:11 }">
                                                <a-input-number v-model="info.Barcode_top_mar" style="width: 60px"/>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="10" offset="1">
                                            <a-form-item style="margin-bottom: 0" label="左边距" :label-col="{ span: 13}"
                                                         :wrapper-col="{span:11}">
                                                <a-input-number v-model="info.Barcode_left_mar" style="width: 60px"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="显示二维码">
                                <a-switch v-model="info.QRcode_show"></a-switch>
                            </a-form-item>
                            <a-form-item label="二维码大小(mm)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" label="宽" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:16 }">
                                                <a-input-number v-model="info.QRcode_width"/>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="10" offset="1">
                                            <a-form-item style="margin-bottom: 0" label="高" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:16 }">
                                                <a-input-number v-model="info.QRcode_height"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                            <a-form-item label="设置边距(mm)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" label="上边距" :label-col="{ span: 13}"
                                                         :wrapper-col="{ span:11 }">
                                                <a-input-number v-model="info.QRcode_top_mar" style="width: 60px"/>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="10" offset="1">
                                            <a-form-item style="margin-bottom: 0" label="左边距" :label-col="{ span: 13}"
                                                         :wrapper-col="{ span:11 }">
                                                <a-input-number v-model="info.QRcode_left_mar" style="width: 60px"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="显示文本内容">
                                <a-switch v-model="info.Text_show"></a-switch>
                            </a-form-item>
                            <a-form-item label="文字大小(px)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" :label-col="{ span: 6}"
                                                         :wrapper-col="{ span:18 }">
                                                <a-input-number v-model="info.Text_size"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                            <a-form-item label="设置边距(mm)">
                                <div>
                                    <a-row>
                                        <a-col :span="10">
                                            <a-form-item style="margin-bottom: 0" label="上边距" :label-col="{ span: 13}"
                                                         :wrapper-col="{ span:11 }">
                                                <a-input-number v-model="info.Text_top_mar" style="width: 60px"/>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="10" offset="1">
                                            <a-form-item style="margin-bottom: 0" label="左边距" :label-col="{ span: 13}"
                                                         :wrapper-col="{ span:11 }">
                                                <a-input-number v-model="info.Text_left_mar" style="width: 60px"/>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row style="padding-top: 25px">

                        <a-col :span="9">
                            <a-form-item label="演示编号" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:13}">
                                <a-input v-model="info.identifier"/>
                            </a-form-item>
                            <a-form-item label="演示文本" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:13}">
                                <a-input v-model="info.Text_value"/>
                            </a-form-item>
                            <a-form-item label="设为默认规则" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16}">
                                <a-space>
                                    <a-switch v-model="info.default_template"></a-switch>
                                    <span style="color: #ff4d4f">*每个类别下只能设置一个默认模板</span>
                                </a-space>
                            </a-form-item>
                        </a-col>

                        <a-col :span="14">
                            <a-form-item label="实时预览" :label-col="{ span: 3, offset: 0 }" :wrapper-col="{ span:20}">
                                <div style="border: solid 1px #d9d9d9;" :style="{
                            width: `${info.paper_width}px`,
                            height:`${info.paper_height}px`}">
                                    <img :src="getImg()" class="w-full h-full" alt="">
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row style="padding-top: 25px">
                        <a-col :span="8">

                        </a-col>
                    </a-row>

                    <a-form-item :wrapper-col="{ span: 1, offset:3 }">
                        <a-button type="primary" @click="doClick()" size="large">保存</a-button>
                    </a-form-item>

                </a-form>


            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time'],
        data() {
            return {
                img_url: '',
                info: {
                    id: 0,
                    identifier: '',
                    tag_type: '',
                    name: '',

                    paper_width: '',
                    paper_height: '',

                    Barcode_show: '',
                    Barcode_width: '',
                    Barcode_height: '',
                    Barcode_top_mar: '',
                    Barcode_left_mar: '',

                    QRcode_show: '',
                    QRcode_width: '',
                    QRcode_height: '',
                    QRcode_top_mar: '',
                    QRcode_left_mar: '',

                    Text_show: '',
                    Text_value: '',
                    Text_size: '',
                    Text_top_mar: '',
                    Text_left_mar: '',

                    default_template: 2,
                },

                value: 1,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
            }
        },
        computed: {},
        watch: {
            edit_info: {
                handler() {
                    this.mountedDo();
                },
                deep: true
            }
        },

        mounted() {
            this.mountedDo()
        },

        methods: {
            //编辑创建标签
            mountedDo() {
                let tag_type = Number(this.$route.query.tag_type) ? Number(this.$route.query.tag_type) : 1;
                console.log('edit_info', JSON.stringify(this.edit_info))
                let info = {}
                info.id = this.edit_info.id
                info.tag_type = this.edit_info.id ? this.edit_info.tag_type : tag_type;
                info.name = this.edit_info.name;
                info.default_template = this.edit_info.is_default;
                info.paper_width = this.edit_info.paper_w;
                info.paper_height = this.edit_info.paper_h;

                info.Barcode_show = this.edit_info.isshow_barcode;
                info.Barcode_width = this.edit_info.barcode_w;
                info.Barcode_height = this.edit_info.barcode_h;
                info.Barcode_top_mar = this.edit_info.barcode_top;
                info.Barcode_left_mar = this.edit_info.barcode_left;

                info.QRcode_show = this.edit_info.isshow_qrcode;
                info.QRcode_width = this.edit_info.qrcode_w;
                info.QRcode_height = this.edit_info.qrcode_h;
                info.QRcode_top_mar = this.edit_info.qrcode_top;
                info.QRcode_left_mar = this.edit_info.qrcode_left;

                info.Text_show = this.edit_info.isshow_text;
                info.Text_size = this.edit_info.text_size;
                info.Text_top_mar = this.edit_info.text_top;
                info.Text_left_mar = this.edit_info.text_left;

                info.Text_value = this.edit_info.demo_text;
                info.identifier = this.edit_info.demo_num;

                this.info = JSON.parse(JSON.stringify(info))
                console.log(JSON.stringify(this.info))

                this.getImg()
            },
            doClick() {
                let data = {
                    id: this.info.id,
                    tag_type: this.info.tag_type,
                    name: this.info.name,

                    is_default: this.info.default_template ? '1' : '2',

                    paper_w: this.info.paper_width,
                    paper_h: this.info.paper_height,

                    isshow_barcode: this.info.Barcode_show ? '1' : '2',
                    barcode_w: this.info.Barcode_width,
                    barcode_h: this.info.Barcode_height,
                    barcode_top: this.info.Barcode_top_mar,
                    barcode_left: this.info.Barcode_left_mar,

                    isshow_qrcode: this.info.QRcode_show ? '1' : '2',
                    qrcode_w: this.info.QRcode_width,
                    qrcode_h: this.info.QRcode_height,
                    qrcode_top: this.info.QRcode_top_mar,
                    qrcode_left: this.info.QRcode_left_mar,

                    isshow_text: this.info.Text_show ? '1' : '2',
                    text_size: this.info.Text_size,
                    text_top: this.info.Text_top_mar,
                    text_left: this.info.Text_left_mar,

                    demo_text: this.info.Text_value,
                    demo_num: this.info.identifier,
                    qrcode_url: this.getImg()
                }
                console.log(
                    JSON.stringify(data)
                )
                this.$sa0.post({
                    url: this.info.id !== 0 ? this.$api('Edit_tag') : this.$api('Create_tag'),
                    data: data,

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            console.log(JSON.stringify(data))
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //生成
            getImg() {
                let data = {
                    i: this.info.identifier,
                    pw: this.info.paper_width,
                    ph: this.info.paper_height,
                    bs: this.info.Barcode_show,
                    qs: this.info.QRcode_show,
                    ts: this.info.Text_show,
                }

                if (this.info.Barcode_show) {
                    data.bw = this.info.Barcode_width
                    data.bh = this.info.Barcode_height
                    data.btm = this.info.Barcode_top_mar
                    data.blm = this.info.Barcode_left_mar
                }

                if (this.info.QRcode_show) {
                    data.qw = this.info.QRcode_width
                    data.qh = this.info.QRcode_height
                    data.qtm = this.info.QRcode_top_mar
                    data.qlm = this.info.QRcode_left_mar
                }

                if (this.info.Text_show) {
                    data.tv = this.info.Text_value
                    data.tsi = this.info.Text_size
                    data.ttm = this.info.Text_top_mar
                    data.tlm = this.info.Text_left_mar
                }
                let q_arr = [];
                // let url = 'http://aaa.aaa.aaa?aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                // ['tsi=1','ttm=1','aaa=1','aaa=1','aaa=1']
                for (let i in data) {
                    q_arr.push(`${i}=${data[i]}`)
                }
                let q_str = q_arr.join('&')
                // 'aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                let url = this.$api('qrCode') + '?' + q_str
                console.log('------', JSON.stringify(this.info))
                return url
            },

            //单选
            onChange(e) {
                console.log('radio checked', e.target.value);
            },
            //select
            handleChange(value) {
                this.info.tag_type = value;
                console.log(`selected ${value}`);
            },
            //form
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

        },
    }
</script>

<style scoped>
    table {
        margin: 15px auto 15px 0;
        width: 90%;
        border-top: solid 1px #d9d9d9;
        border-left: solid 1px #d9d9d9;
    }

    table td, table th {
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 10px 15px;
        line-height: 1;
    }

    table th {
        padding: 12px 15px;
        background: #f5f5f5
    }

    table td.tag {
        background: #f5f5f5;
        width: 18%;
        text-align: right;
        font-weight: bold
    }

    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
</style>
