<template>
    <div class="wraper">
        <JPrint ref="doPrint"></JPrint>
        <div class="wrap-mainer">
            <div class="numsidebar">
                <div class="sidebarmain">
                    <a-tabs v-model="active_key" :default-active-key="active_key" tab-position="left" type="card" @change="tabChange">
                        <a-tab-pane :key="0" tab="全部标签模板"></a-tab-pane>
                        <a-tab-pane :key="1" tab="人员标签模板"></a-tab-pane>
                        <a-tab-pane :key="2" tab="仪器标签模板"></a-tab-pane>
                        <a-tab-pane :key="3" tab="样品标签模板"></a-tab-pane>
                        <a-tab-pane :key="4" tab="委托单标签模板"></a-tab-pane>
                    </a-tabs>
                </div>
                <div class="addbutton">
                    <a-button type="primary" style="width: 100%" @click="showDrawer({
                    id:0,
                    tag_type: '',
                    name: '',

                    paper_w: '',
                    paper_h: '',

                    isshow_barcode: false,
                    barcode_w: '',
                    barcode_h: '',
                    barcode_top: '',
                    barcode_left: '',

                    isshow_qrcode: false,
                    qrcode_w: '',
                    qrcode_h: '',
                    qrcode_top: '',
                    qrcode_left: '',

                    isshow_text: false,
                    text_size: '',
                    text_top: '',
                    text_left: '',

                    demo_text: '',
                    demo_num: '',})">添加标签模板
                    </a-button>
                </div>
            </div>

            <div class="list-right wrap-container">
                <!--全部标签模板-->

                <div class="card" v-for="(item,key) in Get_tag_list">
                    <a-row>
                        <a-col :span="24">
                            <p><span class="font-semibold text-lg">{{ item.name }}</span></p>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="6">
                            <a-form-item label="纸张大小(mm)" :label-col="{ span:15, offset: 0 }" :wrapper-col="{span:9,offset: 0}" style="margin-bottom: 0; margin-top: 0;">
                                <span class="font-semibold">{{ item.paper_w }}</span>×<span class="font-semibold">{{ item.paper_h }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="18">

                            <!--                            <a-form-item label="设为默认模板" :label-col="{ span:8, offset: 0 }"-->
                            <!--                                         :wrapper-col="{span:16,offset: 0}" style="margin-bottom: 0; margin-top: 0;">-->
                            <!--                                <a-space><a-switch :defaultChecked="Number(item.is_default) === 1"-->
                            <!--                                                   @change="isDefault(item)"></a-switch><span style="color: #ff4d4f">*每个类别下只能设置一个默认模板</span></a-space>-->
                            <!--                            </a-form-item>-->

                            <a-form-item label="设为默认模板" :label-col="{ span:8, offset: 0 }" :wrapper-col="{span:16,offset: 0}" style="margin-bottom: 0; margin-top: 0;">
                                <a-space>
                                    <!--                                    {{ item.is_default }}-->
                                    <a-switch v-model="item.is_default" :disabled="item.is_default" @change="isDefault(item)"></a-switch>
                                    <span style="color: #ff4d4f">*每个类别下只能设置一个默认模板</span></a-space>

                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="6">
                            <a-form-item label="条码信息" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-bottom: 0; margin-top: 0;">
                                <a-switch :defaultChecked="Number(item.isshow_barcode) === 1" :disabled="disabled"></a-switch>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <template v-if="Number(item.isshow_barcode) === 1">
                                <a-form-item label="条码大小(mm)" :label-col="{ span: 14, offset: 0 }" :wrapper-col="{span:10}" style="margin-bottom: 0; margin-top: 0;">
                                    <span class="font-semibold">{{ item.barcode_w }}</span>
                                    ×<span class="font-semibold">{{ item.barcode_h }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                        <a-col :span="5">
                            <template v-if="Number(item.isshow_barcode) === 1">
                                <a-form-item label="上边距(mm)" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-bottom: 0; margin-top: 0;">
                                    <span class="font-semibold">{{ item.barcode_top }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                        <a-col :span="5">
                            <template v-if="Number(item.isshow_barcode) === 1">
                                <a-form-item label="左边距(mm)" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-bottom: 0; margin-top: 0;">
                                    <span class="font-semibold">{{ item.barcode_left }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="6">
                            <a-form-item label="二维码信息" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-bottom: 0; margin-top: 0;">
                                <a-switch :defaultChecked="Number(item.isshow_qrcode) === 1" :disabled="disabled"></a-switch>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <template v-if="Number(item.isshow_qrcode) === 1">
                                <a-form-item label="二维码大小(mm)" :label-col="{ span: 14, offset: 0 }" :wrapper-col="{span:10}" style="margin-bottom: 0; margin-top: 0;">
                                    <span class="font-semibold">{{ item.qrcode_w }}</span>×<span class="font-semibold">{{ item.qrcode_h }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                        <a-col :span="5">
                            <template v-if="Number(item.isshow_qrcode) === 1">
                                <a-form-item label="上边距(mm)" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-bottom: 0; margin-top: 0;">
                                    <span class="font-semibold">{{ item.qrcode_top }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                        <a-col :span="5">
                            <template v-if="Number(item.isshow_qrcode) === 1">
                                <a-form-item label="左边距(mm)" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-bottom: 0; margin-top: 0;">
                                    <span class="font-semibold">{{ item.qrcode_left }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                    </a-row>

                    <a-row>

                        <a-col :span="6">
                            <a-form-item label="文本信息" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-top: 0;">
                                <a-switch :defaultChecked="Number(item.isshow_text) === 1" :disabled="disabled"></a-switch>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <template v-if="Number(item.isshow_text) === 1">
                                <a-form-item label="字体大小(px)" :label-col="{ span: 14, offset: 0 }" :wrapper-col="{span:10}" style="margin-top: 0;">
                                    <span class="font-semibold">{{ item.text_size }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                        <a-col :span="5">
                            <template v-if="Number(item.isshow_text) === 1">
                                <a-form-item label="上边距(mm)" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-top: 0;">
                                    <span class="font-semibold">{{ item.text_top }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                        <a-col :span="5">
                            <template v-if="Number(item.isshow_text) === 1">
                                <a-form-item label="左边距(mm)" :label-col="{ span: 16, offset: 0 }" :wrapper-col="{span:8}" style="margin-top: 0;">
                                    <span class="font-semibold">{{ item.text_left }}</span>
                                </a-form-item>
                            </template>
                        </a-col>
                    </a-row>

                    <a-row>

                    </a-row>

                    <a-row>
                        <a-col :span="24">
                            <div style="height: 300px; overflow: auto; border: solid 1px #efefef">
                                <div class="card-tiaoma" :id="[`tiaoma_${key}`]">
                                    <img class="tiaoma" :src="getImg(item)">
                                </div>
                            </div>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24" style=" text-align: right; padding-top: 15px">
                            <a-space>
                                <a-button @click="printClick(getImg(item))" type="primary">打印</a-button>
                                <a-button type="primary" @click="showDrawer(item)">编辑</a-button>
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)" style="float: right">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="danger">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </a-space>
                        </a-col>
                    </a-row>
                </div>

            </div>

        </div>

        <!-----打开新建标签模板窗口------>
        <div v-if="edit_info">
            <biaoqianCreate :edit_info="edit_info" :edit_time="edit_time" :close="onCloseDrawer" :visible="drawer_visible"></biaoqianCreate>
        </div>
    </div>
</template>

<script>
    import biaoqianCreate from './biaoqian-addNew/biaoqian-addNew.vue';
    import JPrint from "../../components/print/JPrint";

    export default {
        components: {
            JPrint,
            biaoqianCreate
        },
        data() {
            return {
                disabled: true,
                Get_tag_list: [],
                edit_info: false,
                edit_time: 0,
                drawer_visible: false,//新建标签模板
                active_key: 0,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
            }
        },
        watch: {
            $route: {
                handler() {
                    // this.item.is_default = Number(this.item.is_default) === 1
                    // 深度监听 路由变化
                    this.mountedDo()
                },
                deep: true
            }
        },

        mounted() {
            this.mountedDo()
        },
        methods: {
            printClick(src) {
                this.$refs.doPrint.printIt([src]);
            },
            mountedDo() {
                this.active_key = this.$route.query.tag_type ? Number(this.$route.query.tag_type) : 0;
                this.Taglist()
            },
            // tab切换
            tabChange(key) {
                console.log(key);
                this.$router.push({
                    query: {
                        tag_type: key
                    }
                })
            },
            onChange(e) {
                console.log('radio checked', e.target.value);
            },
            //是否设为默认模板
            isDefault(item) {
                console.warn('isDefault', item.is_default)
                let data = {
                    id: item.id,
                    tag_type: Number(this.active_key) === 0 ? '' : this.active_key,
                    is_default: item.is_default ? '1' : '2',
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_tag'),
                    data: data
                }).then((response) => {
                    console.log('-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Taglist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            getImg(item) {
                //console.log(JSON.stringify(item))
                let data = {
                    i: item.demo_num,
                    pw: item.paper_w,
                    ph: item.paper_h,
                    bs: Number(item.isshow_barcode) !== 2,
                    qs: Number(item.isshow_qrcode) !== 2,
                    ts: Number(item.isshow_text) !== 2,
                }

                if (Number(item.isshow_barcode) !== 2) {
                    data.bw = item.barcode_w
                    data.bh = item.barcode_h
                    data.btm = item.barcode_top
                    data.blm = item.barcode_left
                }

                if (Number(item.isshow_qrcode) !== 2) {
                    data.qw = item.qrcode_w
                    data.qh = item.qrcode_h
                    data.qtm = item.qrcode_top
                    data.qlm = item.qrcode_left
                }
                if (Number(item.isshow_text) !== 2) {
                    data.tv = item.demo_text
                    data.tsi = item.text_size
                    data.ttm = item.text_top
                    data.tlm = item.text_left
                }
                let q_arr = [];
                // let url = 'http://aaa.aaa.aaa?aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                // ['tsi=1','ttm=1','aaa=1','aaa=1','aaa=1']
                for (let i in data) {
                    q_arr.push(`${i}=${data[i]}`)
                }
                let q_str = q_arr.join('&')
                // 'aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                let url = this.$api('qrCode') + '?' + q_str
                return url
            },

            //标签列表
            Taglist() {
                this.$sa0.post({
                    url: this.$api('Taglist'),
                    data: {
                        tag_type: Number(this.active_key) === 0 ? '' : this.active_key,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_tag_list = []
                            setTimeout(() => {
                                this.Get_tag_list = response.data.list.map((item) => {
                                    item.is_default = Number(item.is_default) === 1
                                    return item
                                });
                            }, 1)
                            console.log('Get_tag_list',)
                            //console.log(JSON.stringify(this.Get_tag_list))
                            //this.count = response.data.result.count;//分页
                            //this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            //删除
            delClick(item) {
                //console.log(item)
                this.$sa0.post({
                    url: this.$api('Del_tag'),
                    data: {
                        id: item.id,
                    },

                }).then((response) => {
                    console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('已删除')
                            this.Taglist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 打开抽屉时的 函数 新建标签模板
            showDrawer(item) {
                let i = JSON.parse(JSON.stringify(item))//切断双向绑定
                this.edit_time = new Date() / 1;
                i.isshow_barcode = Number(i.isshow_barcode) === 1
                i.isshow_qrcode = Number(i.isshow_qrcode) === 1
                i.isshow_text = Number(i.isshow_text) === 1
                i.is_default = Number(i.is_default) === 1
                this.edit_info = i;
                console.log(this.edit_info);
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.edit_info = false;
                this.mountedDo()
            },

            //form
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
        }
    }
</script>

<style>
    .card{
        float: left;
        margin: 0 15px 15px 0;
        border: solid 1px #efefef;
        width: 680px;
        padding: 25px;
        box-sizing: border-box
        }

    /*.card:hover{background: #efefef}*/

    .card-tiaoma{
        margin-top: 25px;
        margin-bottom: 15px;
        width: 300px;
        padding: 15px;
        background: #efefef
        }

    .card-tiaoma img{
        margin: 0 auto
        }

    img.tiaoma{
        width: 220px;
        height: auto;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        }

    img.erweima{
        margin: 15px auto;
        width: 120px;
        height: 120px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        }

    .wenben{
        margin-bottom: 0;
        background: #fff;
        }


    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{
        padding-left: 220px;

        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .addbutton{
        width: 100%;
        text-align: center;
        margin-left: -5px;
        }

    .wraper{
        width: 100%;
        height: 100%;
        display: flex;
        }

    .wrap-mainer{
        flex: 1;
        display: flex;
        }

    .numsidebar{
        position: fixed;
        width: 200px;
        display: flex;
        flex-direction: column;
        background: #f9f9f9;
        padding: 10px;
        padding-right: 0;
        height: calc(100vh - 120px);
        }

    .sidebarmain{
        flex: 1;
        overflow-y: auto;

        }

    .wrap-container{
        flex: 1;
        overflow-y: auto
        }
</style>
