<template>
  <div>
    <div>
      <div id="el">
        <div v-for="(item,index) in pics">
          <img :src="item" @load="onLoad">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JPrint",
  data() {
    return {
      show: false,//是否显示二维码
      pics: [],
      pic_count: 0
    }
  },
  watch: {
    pic_count(val, oldVal) {
      if (val>0 && this.pics.length === val) {
        $("#el").jqprint()
        this.clearPics()
      }
    }
  },
  methods: {
    onLoad() {
      this.pic_count++;
    },
    printIt(pic_list) {
      this.pics = pic_list
    },
    clearPics(){
      this.pics=[]
      this.pic_count=0
    }
  }
}
</script>
<style scoped>

</style>